<template>
  <tr class="product-listing-row">
    <td class="product-title">
      <router-link to='/' class="description">
        {{ product.name }}
      </router-link>
    </td>

    <td class="product-size">
      <cool-select
        ref="input"
        v-model="selectedSize"
        :items="computedProductSizes"
      />
    </td>

    <td class="price">$500.00</td>

    <td class="add-to-cart">
      <div>
        <template-product-quantity />
        <button href="#" class="button">Add</button>
      </div>
    </td>
  </tr>
</template>

<script>
import { CoolSelect } from 'vue-cool-select'
import TemplateProductQuantity from '@/components/common/TemplateProductQuantity'

export default {
  props: ['productId'],
  components: {
    TemplateProductQuantity,
    CoolSelect
  },
  data() {
    return {
      selectedSize: '',
      product: {
        name: 'Dog S100A8, S100A8-DOG-VB',
        sizes: [
          {
            caption: '40MG',
            price: '600.00'
          },
          {
            caption: '10MG',
            price: '200.00'
          },
          {
            caption: '1MG',
            price: '100.00'
          },
        ]
      }
    }
  },
  computed: {
    computedProductSizes() {
      return this.product.sizes.map((x) => x.caption)
    }
  },
  mounted() {
    this.selectedSize = this.product.sizes[0].caption

    // this.axios.get(`/products/${this.productId}`).then((response) => {
    //   this.product = response.data
    // })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/mixins.scss';

:deep(.IZ-select) {
  @apply relative;
  width: 110px;

  input {
    @include select-arrow;
    height: 38px;
    background-position: calc(100% - 15px) 11px !important;
  }
}

.add-to-cart ::v-deep {
  .quantity-less,
  .quantity-value,
  .quantity-add {
    width: 45px;
    height: 38px;
  }

  .quantity-add {
    border-right-width: 0;  
  }
}
</style>