import Vue from 'vue'
import VueRouter from 'vue-router'
import FrontPage from '@/pages/FrontPage.vue'
import CartPage from '@/pages/CartPage.vue'
import ProductDetailPage from '@/pages/ProductDetailPage.vue'
import CategoryPage from '@/pages/CategoryPage.vue'
import ContactPage from '@/pages/ContactPage.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: FrontPage
  },
  {
    path: "/cart",
    component: CartPage
  },
  {
    path: "/pdp",
    component: ProductDetailPage
  },
  {
    path: "/category",
    component: CategoryPage
  },
  {
    path: "/contact",
    component: ContactPage
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router