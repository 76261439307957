<template>
  <div class="container">
    <div class="product">
      <div class="product-main">
        <div class="product-image">
          <img class="image-border" src="@/assets/images/product-detail-image.jpg" />
        </div>
        <div class="product-content">
          <div class="sku">SKU: ABCD1234</div>
          <h1>Anti-cat alpha-1-acid glycoprotein, AGP-08-4F57</h1>
          <div class="price">$145.00 – $600.00</div>
          <p class="description">
            Developed and manufactured at Life Diagnostics, Inc. Purified from serum free media using hydroxyapatite chromatography at neutral pH. AGP-08-4F57...
          </p>
          <div class="product-operations">
            <template-product-quantity />
            <div class="add-to-cart-and-like">
              <a href="#" class="button">Add to cart</a>
              <div class="like">
                <img src="@/assets/images/heart.svg" />
              </div>
            </div>
          </div>
          <template-payment-logos />
        </div>
      </div> <!-- /product -->

      <div class="product-additional-details">
        <ul class="tabs">
          <li class="active">Description</li>
          <li>Specifications</li>
        </ul>
        <p>
          Developed and manufactured at Life Diagnostics, Inc. Purified from serum free media using hydroxyapatite chromatography at neutral pH. AGP-08-4F57 can be used as capture antibody with monoclonals AGP-08-11D2 or AGP-08-11B7 as detection antibodies.
        </p>
      </div> <!-- /product-additional-details -->
    </div> <!-- /container -->
  </div> <!-- /product-details -->
</template>

<script>
import TemplateProductQuantity from '@/components/common/TemplateProductQuantity'
import TemplatePaymentLogos from '@/components/common/TemplatePaymentLogos'

export default {
  components: {
    TemplateProductQuantity,
    TemplatePaymentLogos
  }
}
</script>
