<template>
  <div>
    <template-header />
    <template-hero />
    <template-breadcrumb />

    <div class="container">
      <h1>
        Your Cart: 2 Items 
      </h1>
    </div>

    <template-cart />

    <template-footer />
  </div>
</template>

<script>
import TemplateHeader from '@/components/common/TemplateHeader'
import TemplateHero from '@/components/common/TemplateHero'
import TemplateBreadcrumb from '@/components/common/TemplateBreadcrumb'
import TemplateCart from '@/components/cart/TemplateCart'
import TemplateFooter from '@/components/common/TemplateFooter'

export default {
  components: {
    TemplateHeader,
    TemplateHero,
    TemplateBreadcrumb,
    TemplateCart,
    TemplateFooter
  }
}
</script>

