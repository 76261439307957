<template>
  <div class="faqs">
    <vs-accordion :active="currentIndex" :is-box="true" is-compact>
      <vs-accordion-item
        @change="change"
        v-for="(faq, i) in faqs"
        :key="i"
      >
        <template slot="accordion-trigger">
          {{ faq.question }}
        </template>

        <div>
          {{ faq.answer }}
        </div>
      </vs-accordion-item>
    </vs-accordion>
  </div>
</template>

<script>
import { VsAccordion, VsAccordionItem } from '@vuesimple/vs-accordion'

export default {
  props: ['faqs'],
  components: {
    VsAccordion,
    VsAccordionItem,
  },
  data() {
    return {
      currentIndex: 0,
    }
  },
  methods: {
    change(e) {
      this.currentIndex = e.index;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/variables.scss';

.faqs {
  margin-bottom: 35px;
}

:deep(.vs-accordion__item) {
  border: none !important;
  margin-bottom: 1px !important;

  .vs-accordion__trigger {
    background: $primary;
    color: white;

    .vs-accordion--icon {
      color: white;
    }
  }

  .vs-accordion__content {
    margin-top: 1px;
    border: 1px solid $light-grey;

    .vs-accordion__content-wrapper {
      padding: 10px 10px 13px;

      div {
        color: $secondary;
        font-size: 15px;
      }
    }
  }
}
</style>
