<template>
  <div class="container">
    <div class="contact-page">
      <div class="contact-sidebar">
        <h2>Veterinary Biomarkers</h2>
        <div class="address">
          Phone: <a href="tel:610-431-7707">484-200-9651</a>
          <br />
          Fax: <a href="tel:tel:610-431-7707">484-200-9651</a>
        </div>
        <div class="contact-info">
          general inquires: <a href="mailto:info@vetbiomarkers.com">info@vetbiomarkers.com</a><br />
          support: <a href="techsupport@vetbiomarkers.com">techsupport@vetbiomarkers.com</a>
        </div>
      </div>

      <div class="contact-content">
        <h1>Contact</h1>

        <p>
          Orders may be placed by phone, fax, <a href="mailto:info@vetbiomarkers.com">email</a>, or via our <a href="#">online shop</a>.<br /><br />
          Pricing and information can be accessed on the specific product pages. When placing orders by telephone, fax, or email, please list the items to be ordered, their respective catalog numbers, and include your purchase order number, credit card information (if appropriate), name, and telephone number together with your shipping and billing addresses. If you prefer to pay using PayPal, please include the email address of your PayPal account. Orders will be shipped via FedEx unless requested otherwise. Terms and conditions are listed below.<br /><br />
          Use Statement: All products are for in vitro research use only.
        </p>

        <vs-accordion :faqs="faqs" />

        <div class="contact-form">
          <form action="#">
            <div class="form-section inquiry-section">
              <label>
                <span>Inquiry</span>

                <cool-select
                  ref="input"
                  v-model="inquiry"
                  :items="inquiryTypes"
                />
              </label>
            </div>

            <div class="mid-form-section">
              <div class="form-section">
                <label>
                  <span>First Name</span>
                  <input name="user_first_name" type="text" />
                </label>
              </div>

              <div class="form-section">
                <label>
                  <span>Last Name</span>
                  <input name="user_last_name" type="text" />
                </label>
              </div>

              <div class="form-section">
                <label>
                  <span>Email</span>
                  <input name="user_email" type="text" />
                </label>
              </div>

              <div class="form-section">
                <label>
                  <span>Phone</span>
                  <input name="user_phone" type="text" />
                </label>
              </div>

              <div class="form-section">
                <label>
                  <span>Company</span>
                  <input name="user_company" type="text" />
                </label>
              </div>

              <div class="form-section">
                <label>
                  <span>Country</span>
                  <input name="user_country" type="text" />
                </label>
              </div>
            </div> <!-- /mid-form-section -->

            <div class="form-section message-section">
              <label>
                <span>Your Message</span>
                <textarea name="user_message" placeholder="Your message"></textarea>
              </label>
            </div>

            <div class="form-section file-section">
              <span>Attach File</span>
              <label class="file-label" for="files">
                {{ fileFieldLabel }}
              </label>
              <input id="files" style="display:none;" type="file" @change="pickFile($event)">
            </div>
            
            <div class="form-section">
              <label>
                <input type="submit" value="Submit" class="submit-button-desktop button" />
              </label>
            </div>
          </form>
        </div>
      </div>
    </div> <!-- /contact-page -->
  </div> <!-- /container -->
</template>

<script>
import { CoolSelect } from 'vue-cool-select'
import VsAccordion from '@/components/common/VsAccordion'

export default {
  components: {
    CoolSelect,
    VsAccordion
  },
  data() {
    return {
      inquiry: '',
      fileFieldLabel: '',
      fileFieldLabelDefault: 'Browse Files',
      faqs: [
        {
          question: "Question 1",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore 1."
        },
        {
          question: "Question 2",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore 2."
        },
        {
          question: "Question 3",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore 3."
        },
        {
          question: "Question 4",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore 3."
        },
        {
          question: "Question 5",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore 3."
        },
        {
          question: "Question 6",
          answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore 3."
        }
      ]
    }
  },
  computed: {
    inquiryTypes() {
      return [
        "General Inquiry",
        "Technical Inquiry",
        "Business Inquiry",
      ];
    }
  },
  mounted() {
    this.fileFieldLabel = this.fileFieldLabelDefault;
  },
  methods: {
    pickFile (event) {
      const files = event?.target?.files;
      let fileName = files[0]?.name;
      this.fileFieldLabel = fileName ?? this.fileFieldLabelDefault;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/mixins.scss';

:deep(.IZ-select) {
  input {
    @include select-arrow;
  }
}
</style>