<template>
  <div class="header">
    <div class="container">
      <div class="logo">
        <router-link to='/'>
          <img src="@/assets/images/logo.png" />
        </router-link>
      </div>

      <div class="desktop-menu">
        <div class="top-menu">
          <template-secondary-menu />
        </div>

        <div class="main-menu">
          <template-main-menu />
        </div>
      </div>

      <div
        @click="toggleMobileMenu"
        :class="{ 'active': mobileMenuOpen }"
        class="hamburger-icon"
      >
        <span></span>
      </div>

      <transition name="mobile-menu">
        <div v-show="mobileMenuOpen" class="mobile-menu">
          <template-main-menu />
          <template-secondary-menu />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import $ from 'jquery';
import TemplateMainMenu from '@/components/common/TemplateMainMenu'
import TemplateSecondaryMenu from '@/components/common/TemplateSecondaryMenu'

export default {
  components: {
    TemplateMainMenu,
    TemplateSecondaryMenu
  },
  data () {
    return {
      mobileMenuOpen: false
    }
  },
  methods: {
    toggleMobileMenu() {
      const hamburgerIcon = document.querySelector('.hamburger-icon')
      const body = document.querySelector('body')
      if (hamburgerIcon.matches('.active')) {
        hamburgerIcon.classList.remove('active')
        body.classList.remove('lock')
        this.mobileMenuOpen = false
      } else {
        hamburgerIcon.classList.add('active')
        body.classList.add('lock')
        this.mobileMenuOpen = true
      }
    },
    hoverMenuItem(e, css, el) {
      const subMenu = el.querySelector('.sub-menu')
      if (subMenu) {
        subMenu.style.display = css
      }

      if (css === 'block') {
        el.classList.add('active')
      } else {
        el.classList.remove('active')
      }
    },
  },
  mounted() {
    const breakPoint = 960;

    // desktop menu:
    document.querySelectorAll('.main-menu > ul > li').forEach(el => 
      el.addEventListener('mouseover', (e) => {
        if (window.innerWidth > breakPoint) {
          this.hoverMenuItem(e, 'block', el)
        }
      })
    );

    document.querySelectorAll('.main-menu > ul > li').forEach(el => 
      el.addEventListener('mouseleave', (e) => {
        if (window.innerWidth > breakPoint) {
          this.hoverMenuItem(e, 'none', el)
        }
      })
    );

    // mobile menu:
    let slideDown = (target, duration=300) => {
      target.style.removeProperty('display');
      let display = window.getComputedStyle(target).display;
      if (display === 'none') display = 'block';
      target.style.display = display;
      let height = target.offsetHeight;
      target.style.overflow = 'hidden';
      target.style.height = 0;
      target.style.paddingTop = 0;
      target.style.paddingBottom = 0;
      target.style.marginTop = 0;
      target.style.marginBottom = 0;
      target.offsetHeight;
      target.style.boxSizing = 'border-box';
      target.style.transitionProperty = 'height, margin, padding';
      target.style.transitionDuration = duration + 'ms';
      target.style.height = height + 'px';
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      window.setTimeout( () => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
      }, duration);
    }
    
    $(document).ready(function() {
      $('.mobile-menu li').click(function(e) {
        e.stopPropagation();
      })

      $('.mobile-menu li.menu-item-has-children').click(function(e) {
        if (window.innerWidth <= breakPoint) {
          if ($(this).hasClass('active')) {
            $(this).removeClass('active').children('.sub-menu').hide();
            // console.log($(this))
          } else {
            $('.mobile-menu .sub-menu').hide();
            $('.mobile-menu li.menu-item-has-children').removeClass('active');
            const parentLi = $(this).parents('li')
            if (parentLi) {
              parentLi.addClass('active');
            }
            const subMenuOfLi = $(this).closest('.sub-menu');
            if (subMenuOfLi) {
              subMenuOfLi.show();
            } else {
              $('.mobile-menu .sub-menu').hide();
            }

            const subMenuDomElement = $(this).addClass('active').children('.sub-menu').get(0);
            if (subMenuDomElement) {
              slideDown(subMenuDomElement);
            }            
          }
        }
      })
    })

    /*
    function hide_mobile_menu() {
      const mobileMenuButton = $('.hamburger-icon');
      const mainMenu = $('.mobile-menu');
      if ($(window).width() > breakPoint) {
      }
    }
    hide_mobile_menu();
    $(window).on('resize', hide_mobile_menu);
    */
  },
  beforeDestroy() {
    document.querySelectorAll('.main-menu > ul > li').forEach(el => {
      el.removeEventListener('mouseover', this.hoverMenuItem)
      el.removeEventListener('mouseleave', this.hoverMenuItem)
    })
  }
}
</script>