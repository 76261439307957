<template>
  <div>
    <template-header />
    <template-hero />
    <template-breadcrumb />

    <template-products-index />

    <template-contact-form />
    <template-footer />
  </div>
</template>

<script>
import TemplateHeader from '@/components/common/TemplateHeader'
import TemplateHero from '@/components/common/TemplateHero'
import TemplateBreadcrumb from '@/components/common/TemplateBreadcrumb'
import TemplateProductsIndex from '@/components/category/TemplateProductsIndex'
import TemplateContactForm from '@/components/common/TemplateContactForm'
import TemplateFooter from '@/components/common/TemplateFooter'

export default {
  components: {
    TemplateHeader,
    TemplateHero,
    TemplateBreadcrumb,
    TemplateProductsIndex,
    TemplateContactForm,
    TemplateFooter
  }
}
</script>
