<template>
  <section class="featured-products">
    <div class="container">
      <h2>
        Our Featured Products
      </h2>

      <ul class="cards">
        <a href="#" class="card" data-aos="flip-up" data-aos-easing="ease-out-cubic" data-aos-duration="900">
          <img src="@/assets/images/featured-product-card.jpg" />
          <div class="card-title">
            Dog S100A8, S100A8-DOG-VB
          </div>
          <div class="card-text">
            $500
          </div>
        </a>

        <a href="#" class="card" data-aos="flip-down" data-aos-easing="ease-out-cubic" data-aos-duration="700">
          <img src="@/assets/images/featured-product-card.jpg" />
          <div class="card-title">
            Dog Procalcitonin, PCT-DOG-VB
          </div>
          <div class="card-text">
            $500
          </div>
        </a>

        <a href="#" class="card" data-aos="flip-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
          <img src="@/assets/images/featured-product-card.jpg" />
          <div class="card-title">
            Dog Lactoferrin, LF-DOG-VB
          </div>
          <div class="card-text">
            $500
          </div>
        </a>

        <a href="#" class="card" data-aos="flip-down" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
          <img src="@/assets/images/featured-product-card.jpg" />
          <div class="card-title">
            Cat Haptoglobin, HAPT-CAT-VB
          </div>
          <div class="card-text">
            $500
          </div>
        </a>
      </ul>

      <a v-if="isFrontPage" href="#" class="button">
        View all
      </a>
    </div> <!-- /container -->
  </section>
</template>

<script>
export default {
  props: {
    isFrontPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      title: this.isFrontPage ? 'Our Featured Products' : 'Customers Also Bought'
    }
  }
}
</script>