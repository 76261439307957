<template>
  <div>
    <template-header />
    <template-hero />
    <template-breadcrumb />

    <template-product-detail />

    <template-featured-products />

    <template-footer />
  </div>
</template>

<script>
import TemplateHeader from '@/components/common/TemplateHeader'
import TemplateHero from '@/components/common/TemplateHero'
import TemplateBreadcrumb from '@/components/common/TemplateBreadcrumb'
import TemplateProductDetail from '@/components/pdp/TemplateProductDetail'
import TemplateFeaturedProducts from '@/components/common/TemplateFeaturedProducts'
import TemplateFooter from '@/components/common/TemplateFooter'

export default {
  components: {
    TemplateHeader,
    TemplateHero,
    TemplateBreadcrumb,
    TemplateProductDetail,
    TemplateFeaturedProducts,
    TemplateFooter
  }
}
</script>

