<template>
  <div class="cart-wrap">
    <div class="container">
      <table class="cart">
        <thead>
          <tr>
            <th class="product">Product</th>
            <th class="quantity">Quantity</th>
            <th class="price">Price</th>
            <th class="subtotal">Subtotal</th>
          </tr>
        </thead>

        <tbody class="cart-items">
          <tr class="cart-item">
            <td data-label="product" class="product">
              <div class="product-inner">
                <div class="product-image">
                  <img class="image-border" src="@/assets/images/product-image.jpg" />
                </div>
                <div class="product-description">
                  <h3>SKU: ABCD1234</h3>
                  <p>Anti-cat alpha-1-acid glycoprotein, AGP-08-4F57</p>
                </div>
              </div>
            </td>

            <td data-label="quantity" class="quantity">
              <template-product-quantity />
            </td>

            <td data-label="price" class="price">$145.00</td>

            <td data-label="subtotal" class="subtotal">$145.00</td>
          </tr> <!-- /cart-item -->

          <tr class="cart-item">
            <td data-label="product" class="product">
              <div class="product-inner">
                <div class="product-image">
                  <img class="image-border" src="@/assets/images/product-image.jpg" />
                </div>
                <div class="product-description">
                  <h3>SKU: ABCD1234</h3>
                  <p>Anti-Dog IgE Monoclonal, IGE-4-13H5, 1 mg</p>
                </div>
              </div>
            </td>

            <td data-label="quantity" class="quantity">
              <template-product-quantity />
            </td>

            <td data-label="price" class="price">$500.00</td>

            <td data-label="subtotal" class="subtotal">$1,000.00</td>
          </tr> <!-- /cart-item -->
        </tbody>
      </table>

      <div class="cart-bottom">
        <div class="links">
          <div class="leave-cart">
            <a href="#" class="continue-shopping link-type1">Continue Shopping</a>
          </div>
          <div class="empty-cart">
            <span href="#" class="delete-cart link-type1">Delete Entire Cart</span>
          </div>
        </div>

        <table class="subtotals">
          <tr>
            <th>
              Subtotal:
            </th>
            <td data-label="subtotal" >
              $1,145.00
            </td>
          </tr>
          <tr>
            <th>
              Shipping:
            </th>
            <td data-label="shipping" >
              $46.50
            </td>
          </tr>
          <tr>
            <th>
              TOTAL:
            </th>
            <td data-label="total" >
              $1,191.60
            </td>
          </tr>
        </table>
      </div>

      <div class="cart-submit">
        <div class="cart-submit-inner">
          <div>
            <a href="#" class="button">Checkout</a>
          </div>
          <template-payment-logos />
        </div>
      </div>
    </div> <!-- /container -->
  </div> <!-- /cart-wrap -->
</template>

<script>
import TemplateProductQuantity from '@/components/common/TemplateProductQuantity'
import TemplatePaymentLogos from '@/components/common/TemplatePaymentLogos'

export default {
  components: {
    TemplateProductQuantity,
    TemplatePaymentLogos
  }
}
</script>