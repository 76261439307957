<template>
  <div class="quantity-inner">
    <div class="quantity-less">
      <span v-if="quantity == 1" class="icon-bin"></span>
      <span v-else class="icon-minus"></span>
    </div>
    <div class="quantity-value">
      {{ quantity }}
    </div>
    <div class="quantity-add">
      <span class="icon-plus"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quantity: 1
    }
  }
}
</script>