<template>
  <div class="container">
    <div class="category">
      <div class="category-sidebar">
        <template-products-by-category />
        <template-products-by-species />
        <template-products-by-marker />
      </div>

      <div class="category-content">
        <h1>Acute Phase Protein</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.</p>
        <img class="category-image image-border" src="@/assets/images/category-image.jpg" />

        <table class="products">
          <template-products-listing-row
            v-for="(product, i) in products"
            :key="i"
            :productId="product"
          />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateProductsByCategory from '@/components/category/TemplateProductsByCategory'
import TemplateProductsByMarker from '@/components/category/TemplateProductsByMarker'
import TemplateProductsBySpecies from '@/components/category/TemplateProductsBySpecies'
import TemplateProductsListingRow from '@/components/category/TemplateProductsListingRow'

export default {
  components: {
    TemplateProductsByCategory,
    TemplateProductsByMarker,
    TemplateProductsBySpecies,
    TemplateProductsListingRow
  },
  data() {
    return {
      products: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    }
  },
}
</script>
