<template>
  <div>
    <template-header />
    <template-hero />
    <template-breadcrumb />

    <template-contact />

    <template-footer />
  </div>
</template>

<script>
import TemplateHeader from '@/components/common/TemplateHeader'
import TemplateHero from '@/components/common/TemplateHero'
import TemplateBreadcrumb from '@/components/common/TemplateBreadcrumb'
import TemplateContact from '@/components/contact/TemplateContact'
import TemplateFooter from '@/components/common/TemplateFooter'

export default {
  components: {
    TemplateHeader,
    TemplateHero,
    TemplateBreadcrumb,
    TemplateContact,
    TemplateFooter
  }
}
</script>
